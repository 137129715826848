import * as echarts from 'echarts/core';
import {
  BarChart,
  // 系列类型的定义后缀都为 SeriesOption
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
  PieChart,
  PieSeriesOption,
  MapChart
} from 'echarts/charts';
import type { ECharts } from 'echarts';
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
  // 数据集组件
  DatasetComponent,
  DatasetComponentOption,
  // 内置数据转换器组件 (filter, sort)
  TransformComponent
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import emitter from '@/eventbus/index';
// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
export type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
  | PieSeriesOption
  | LegendComponentOption
  | DataZoomComponentOption
>;

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  PieChart,
  LegendComponent,
  DataZoomComponent,
  MapChart
]);

import ChinaData from '../data/china.json';
echarts.registerMap('china', ChinaData);

interface EChartsHook {
  echartInstance: echarts.ECharts;
  setOption: ECharts['setOption'];
  resize: ECharts['resize'];
}

export default function (dom: HTMLDivElement | null): EChartsHook {
  const echartInstance = echarts.init(dom);
  let tooltipStatus = true;
  const setOption = (options: ECOption) => {
    echartInstance.setOption(options);
  };
  window.addEventListener(
    'resize',
    () => {
      echartInstance.resize();
    },
    { passive: true }
  );

  echartInstance.on('click', (param: any) => {
    emitter.emit('sss', param);
  });

  echartInstance.getZr().on('click', () => {
    tooltipStatus = false;
  });

  window.addEventListener(
    'click',
    () => {
      if (tooltipStatus) {
        echartInstance.dispatchAction({
          type: 'hideTip'
        });
      }
      tooltipStatus = true;
    },
    { passive: true }
  );

  const resize = () => {
    echartInstance.resize();
  };

  return { echartInstance, setOption, resize };
}
